<template>
    <div id="secure">
        <h1>Neue Bilder-Sequenz {{ leftrightString }}</h1>
        <div v-if="step === 1" class="md-layout md-alignment-top-center">
            <md-card class="md-layout-item md-size-90">
                <md-card-header>
                    <div class="md-title">Bilder auswählen</div>
                </md-card-header>
                <md-card-content>
                    <form>
                        <table>
                            <tr v-if="leftright === -1 || leftright === 0">
                                <td>
                                    <md-icon v-if="paw.leftWithFoot.ready" style="color: green;">done</md-icon>
                                    <md-icon v-else style="color: red;">clear</md-icon>
                                </td>
                                <td style="font-weight: bold">
                                    links mit Fuß
                                </td>
                                <td>
                                    <md-button :disabled="false" class="md-raised" @click="upload(paw.leftWithFoot)">
                                        <md-icon>perm_media</md-icon>
                                    </md-button>
                                    <md-button v-if="!mobileCheck" :disabled="false" class="md-raised"
                                               @click="newImage(paw.leftWithFoot)">
                                        <md-icon>add_a_photo</md-icon>
                                    </md-button>
                                </td>
                            </tr>
                            <tr v-if="leftright === -1 || leftright === 0">
                                <td>
                                    <md-icon v-if="paw.leftWithoutFoot.ready" style="color: green;">done</md-icon>
                                    <md-icon v-else style="color: red;">clear</md-icon>
                                </td>
                                <td style="font-weight: bold">
                                    links ohne Fuß
                                </td>
                                <td>
                                    <md-button :disabled="false" class="md-raised" @click="upload(paw.leftWithoutFoot)">
                                        <md-icon>perm_media</md-icon>
                                    </md-button>
                                    <md-button v-if="!mobileCheck" :disabled="false" class="md-raised"
                                               @click="newImage(paw.leftWithoutFoot)">
                                        <md-icon>add_a_photo</md-icon>
                                    </md-button>
                                </td>
                            </tr>
                            <tr v-if="leftright === -1 || leftright === 1">
                                <td>
                                    <md-icon v-if="paw.rightWithFoot.ready" style="color: green;">done</md-icon>
                                    <md-icon v-else style="color: red;">clear</md-icon>
                                </td>
                                <td style="font-weight: bold">
                                    rechts mit Fuß
                                </td>
                                <td>
                                    <md-button :disabled="false" class="md-raised" @click="upload(paw.rightWithFoot)">
                                        <md-icon>perm_media</md-icon>
                                    </md-button>
                                    <md-button v-if="!mobileCheck" :disabled="false" class="md-raised"
                                               @click="newImage(paw.rightWithFoot)">
                                        <md-icon>add_a_photo</md-icon>
                                    </md-button>
                                </td>
                            </tr>
                            <tr v-if="leftright === -1 || leftright === 1">
                                <td>
                                    <md-icon v-if="paw.rightWithoutFoot.ready" style="color: green;">
                                        done
                                    </md-icon>
                                    <md-icon v-else style="color: red;">clear</md-icon>
                                </td>
                                <td style="font-weight: bold">
                                    rechts ohne Fuß
                                </td>
                                <td>
                                    <md-button :disabled="false" class="md-raised" @click="upload(paw.rightWithoutFoot)">
                                        <md-icon>perm_media</md-icon>
                                    </md-button>
                                    <md-button v-if="!mobileCheck" :disabled="false" class="md-raised"
                                               @click="newImage(paw.rightWithoutFoot)">
                                        <md-icon>add_a_photo</md-icon>
                                    </md-button>
                                </td>
                            </tr>
                        </table>
                    </form>
                    <div>
                        Bitte schieße jetzt die nötigen Bilder oder lade sie hoch.
                        <br>
                        Unterstützt werden aktuell nur Bilder im JPG-Format.
                    </div>
                </md-card-content>
                <md-card-actions>
                    <md-button :disabled="!validImages" class="md-raised md-primary" v-on:click="stepForward()">
                        Weiter
                    </md-button>
                </md-card-actions>
            </md-card>
        </div>

        <div v-if="step === 2" class="md-layout md-alignment-top-center">
            <md-card class="md-layout-item md-small-size-90">
                <md-card-header>
                    <div class="md-title">Angaben prüfen</div>
                </md-card-header>
                <md-card-content>
                    Die folgenden Bilder werden gespeichert.
                    <br>
                    <table id="table">
                        <!--
                        <tr>
                            <th>PAW-Bezeichnung</th>
                            <td>{{ paw_description }}</td>
                        </tr>
                        -->
                        <tr v-if="leftright === -1 || leftright === 0">
                            <td colspan="2">
                                <table class="tabelle_bilder">
                                    <tr>
                                        <th>mit Fuß</th>
                                        <th>ohne Fuß</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img :src="paw.leftWithFoot.data" alt="Bild links mit Fuß"
                                                 v-on:click="imageDetails('bild_linksMitFuss')">
                                        </td>
                                        <td>
                                            <img :src="paw.leftWithoutFoot.data" alt="Bild links ohne Fuß"
                                                 v-on:click="imageDetails('bild_linksOhneFuss')">
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr v-if="leftright === -1 || leftright === 1">
                            <td colspan="2">
                                <table class="tabelle_bilder">
                                    <tr>
                                        <th>mit Fuß</th>
                                        <th>ohne Fuß</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img :src="paw.rightWithFoot.data" alt="Bild rechts mit Fuß"
                                                 v-on:click="imageDetails('bild_rechtsMitFuss')">
                                        </td>
                                        <td>
                                            <img :src="paw.rightWithoutFoot.data" alt="Bild rechts ohne Fuß"
                                                 v-on:click="imageDetails('bild_rechtsOhneFuss')">
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </md-card-content>
                <md-card-actions>
                    <md-button class="md-raised md-primary" v-on:click="stepBack()">
                        Zurück
                    </md-button>
                    <md-button class="md-raised md-primary" v-on:click="sendIt()">
                        Speichern
                    </md-button>
                </md-card-actions>
            </md-card>
        </div>

        <div id="bttn" style="display: none"></div>

        <md-button class="md-raised md-accent" to="/user/dashboard/">Abbrechen</md-button>

        <CameraDialog :active="showCamDial" :img="tmp_image" @onNewImage="imageCaptured(tmp_image)"/>
    </div>
</template>

<script>
import User_CameraDialog from "./User_CameraDialog";
import mobileCheck from "@/helpers/mobileCheck";

const emptyImg = "data:image/jpeg;base64,";

export default {
    name: 'User_PAW_addImages',
    components: {
        CameraDialog: User_CameraDialog
    },
    props: {},
    data: () => ({
        showCamDial: false,
        dialDelete: false,
        dialImageDetail: false,
        deletePAWid: null,
        imageDetail: null,

        step: 1,
        sending: false,

        paw: {
            id: undefined,
            leftWithFoot: {
                ready: false,
                data: emptyImg
            },
            leftWithoutFoot: {
                ready: false,
                data: emptyImg
            },
            rightWithFoot: {
                ready: false,
                data: emptyImg
            },
            rightWithoutFoot: {
                ready: false,
                data: emptyImg
            },
        },

        paw_detail: null,
        tmp_image: undefined,

        leftrightVal: undefined,
    }),
    mounted() {
        this.paw.id = this.$route.params.pawId;
        this.$store.dispatch("loadPawDetails", this.$route.params.pawId).then(() => {
            this.paw_detail = this.$store.state.paw_detail
        })
    },
    computed: {
        mobileCheck,
        paw_description: function () {
            if (!this.paw_detail)
                return ""
            else
                return this.paw_detail.description
        },
        leftright: function () {
            if(this.$route.params.leftright)
                return parseInt(this.$route.params.leftright)
            return -1
        },
        leftrightString: function () {
            switch (this.leftright) {
                case 0:
                    return "(links)";
                case 1:
                    return "(rechts)";
                default:
                    return "";
            }
        },
        validImages: function (){
            const l = this.paw.leftWithFoot.ready && this.paw.leftWithoutFoot.ready;
            const r = this.paw.rightWithFoot.ready && this.paw.rightWithoutFoot.ready;
            switch (this.leftright) {
                case 0:
                    return l;
                case 1:
                    return r;
                default:
                    return l && r;
            }
        }
    },
    methods: {
        stepForward() {
            this.step += 1
        },
        stepBack() {
            this.step -= 1
        },
        imageDetails(name) {
            this.imageDetail = name;
            this.dialImageDetail = true
        },
        imageCaptured(elem) {
            elem.ready = true
        },
        upload(elem) {
            const t = this
            document.getElementById("bttn").innerHTML = ""
            let input = document.createElement('input');
            input.type = 'file';
            input.accept = ".jpeg,.jpg";
            input.style = "display: none";
            input.onchange = (event) => {
                let input = event.target;

                let reader = new FileReader();
                reader.onload = function(){
                    if(t.cleanImage(reader.result).startsWith("/9j/")) {
                        elem.data = reader.result;
                        elem.ready = true
                    } else {
                        elem.ready = false
                        t.$store.dispatch("showSnackbar", {text: "Das ist kein JPG-Bild!"})
                    }
                };
                reader.readAsDataURL(input.files[0]);
            };
            document.getElementById("bttn").appendChild(input)
            input.click()
        },
        /**
         * Opens the Camera-Dialog for
         * @param elem Object for captured image
         */
        newImage(elem) {
            this.tmp_image = elem;
            this.showCamDial = true;
            setTimeout(() => {
                this.showCamDial = false;
            }, 300)
        },
        /**
         * Delete the image-header from the data.
         * @param imageData
         * @returns {*}
         */
        cleanImage(imageData) {
            return imageData.replace(/data:[a-z]+\/.+;base64,/, "")
        },
        /**
         * Send the one/two PawPicSets.
         */
        async sendIt() {
            let l = this.leftright === 1 && this.leftright !== -1;
            let r = this.leftright === 0 && this.leftright !== -1;
            if (this.leftright === -1 || this.leftright === 0) {
                let reqLeft = {
                    pawId: this.paw.id,
                    feetType: 0,
                    feet: this.cleanImage(this.paw.leftWithFoot.data),
                    imprint: this.cleanImage(this.paw.leftWithoutFoot.data)
                }
                if(this.leftright === -1) {
                    reqLeft.percent = {
                        start: 0,
                        end: 50,
                    }
                }
                await this.$store.dispatch("addPawPicsUsingProgressBar", reqLeft).then(() => {
                    l = true
                });
            }
            if (this.leftright === -1 || this.leftright === 1) {
                let reqRight = {
                    pawId: this.paw.id,
                    feetType: 1,
                    feet: this.cleanImage(this.paw.rightWithFoot.data),
                    imprint: this.cleanImage(this.paw.rightWithoutFoot.data)
                }
                if(this.leftright === -1) {
                    reqRight.percent = {
                        start: 50,
                        end: 100,
                    }
                }
                await this.$store.dispatch("addPawPicsUsingProgressBar", reqRight).then(() => {
                    r = true
                });
            }
            if(l && r) {
                this.$router.replace("/user/paw/detail/" + this.paw.id);
                this.$store.dispatch("showSnackbar", {
                    text: "Bilder erfolgreich gespeichert."
                })
            } else if ((l && !r) || (!l && r)) {
                this.$store.dispatch("showSnackbar", {
                    text: "Fehler: Es konnte nur ein Bildersatz gespeichert werden."
                })
                this.$router.replace("/user/paw/detail/" + this.paw.id);
            } else {
                this.$store.dispatch("showSnackbar", {
                    text: "Es ist ein Fehler aufgetreten."
                })
            }
        }
    }
}
</script>

<style scoped>
#secure {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}

/*
div#lupe {
    width: 175px; height: 175px;
    position: relative;
    border-radius: 100%;
    background-color: black;
    opacity: 0;
    transition: opacity 0.5s;
}

#lupe.fade {
    opacity: 1;
}
 */

table {
    display: inline-block;
    border-collapse: collapse;
}

table > tr > th {
    font-size: large;
    padding: 10px;
    white-space: nowrap;
}

table > tr > td {
    padding: 10px;
}

table > tr:nth-child(odd) {
    background: #EEE;
}

table > tr:nth-child(even) {
    background: #FFF;
}
</style>


<!-- TODO Werte validieren -->
